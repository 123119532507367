/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'BR Firma Regular';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma Regular'), url('BR Firma Regular.woff') format('woff');
    src: local('BR Firma Regular'), url('BR Firma Regular.woff2') format('woff2'),
    url('BR Firma Regular.eot') format('eot'), url('BR Firma Regular.svg#BR Firma Regular') format('svg'),
    url('BR Firma Regular.ttf') format('ttf'), 
    }

    @font-face {
    font-family: 'BR Firma Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma Regular Italic'), url('BR Firma Regular Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma Thin';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma Thin'), url('BR Firma Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma Thin Italic'), url('BR Firma Thin Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma ExtraLight'), url('BR Firma Extra Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma ExtraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma ExtraLight Italic'), url('BR Firma Extra Light Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma Light';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma Light'), url('BR Firma Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma Light Italic'), url('BR Firma Light Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma Medium';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma Medium'), url('BR Firma Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma Medium Italic'), url('BR Firma Medium Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma SemiBold'), url('BR Firma SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma SemiBold Italic'), url('BR Firma SemiBold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma Bold';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma Bold'), url('BR Firma Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma Bold Italic'), url('BR Firma Bold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma Black';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma Black'), url('BR Firma Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'BR Firma Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('BR Firma Black Italic'), url('BR Firma Black Italic.woff') format('woff');
    }