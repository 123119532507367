.slideInRight {
    animation-duration: 1s;
    animation-name: SIL;
}
.slideInLeft {
    animation-duration: 1s;
    animation-name: SIR;
}
.fadeInA {
    animation-duration: 0.5s;
    animation-name: FI;
}
.fadeInB {
    animation-duration: 1s;
    animation-name: FI;
}
.fadeInC {
    animation-duration: 1s;
    animation-name: FI;
}
.fadeInD {
    animation-duration: 1.5s;
    animation-name: FI;
}
.fadeInE {
    animation-duration: 2s;
    animation-name: FI;
}

.fadeInF {
    animation-duration: 2.5s;
    animation-name: FI;
}

.fadeInG {
    animation-duration: 3s;
    animation-name: FI;
}

@keyframes SIL {
    from {
        transform: translate(-60%);
        opacity: 0;
    }
    to {
        transform: translate(0%);
        opacity: 1;
    }
}

@keyframes SIR {
    from {
        transform: translate(60%);
        opacity: 0;
    }
    to {
        transform: translate(0%);
        opacity: 1;
    }
}

@keyframes SOL {
    to {
        transform: translate(60%);
        opacity: 0;
    }
    from {
        transform: translate(0%);
        opacity: 1;
    }
}

@keyframes FI {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.transformCustom {
    transform: translate(-60%);
}

.transformCustomB {
    transform: translate(60%);
}

.rotateCustom {
    transform: rotate(180deg);
}