/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 14, 2021 */



@font-face {
    font-family: 'br_firmablack_italic';
    src: url('br_firma_black_italic.eot');
    src: url('br_firma_black_italic.eot?#iefix') format('embedded-opentype'),
         url('br_firma_black_italic.woff2') format('woff2'),
         url('br_firma_black_italic.woff') format('woff'),
         url('br_firma_black_italic.ttf') format('truetype'),
         url('br_firma_black_italic.svg#br_firmablack_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'br_firmablack';
    src: url('br_firma_black.eot');
    src: url('br_firma_black.eot?#iefix') format('embedded-opentype'),
         url('br_firma_black.woff2') format('woff2'),
         url('br_firma_black.woff') format('woff'),
         url('br_firma_black.ttf') format('truetype'),
         url('br_firma_black.svg#br_firmablack') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'br_firmabold_italic';
    src: url('br_firma_bold_italic.eot');
    src: url('br_firma_bold_italic.eot?#iefix') format('embedded-opentype'),
         url('br_firma_bold_italic.woff2') format('woff2'),
         url('br_firma_bold_italic.woff') format('woff'),
         url('br_firma_bold_italic.ttf') format('truetype'),
         url('br_firma_bold_italic.svg#br_firmabold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'br_firmabold';
    src: url('br_firma_bold.eot');
    src: url('br_firma_bold.eot?#iefix') format('embedded-opentype'),
         url('br_firma_bold.woff2') format('woff2'),
         url('br_firma_bold.woff') format('woff'),
         url('br_firma_bold.ttf') format('truetype'),
         url('br_firma_bold.svg#br_firmabold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'br_firmaextralight_italic';
    src: url('br_firma_extra_light_italic.eot');
    src: url('br_firma_extra_light_italic.eot?#iefix') format('embedded-opentype'),
         url('br_firma_extra_light_italic.woff2') format('woff2'),
         url('br_firma_extra_light_italic.woff') format('woff'),
         url('br_firma_extra_light_italic.ttf') format('truetype'),
         url('br_firma_extra_light_italic.svg#br_firmaextralight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'br_firmaextralight';
    src: url('br_firma_extra_light.eot');
    src: url('br_firma_extra_light.eot?#iefix') format('embedded-opentype'),
         url('br_firma_extra_light.woff2') format('woff2'),
         url('br_firma_extra_light.woff') format('woff'),
         url('br_firma_extra_light.ttf') format('truetype'),
         url('br_firma_extra_light.svg#br_firmaextralight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'br_firmalight_italic';
    src: url('br_firma_light_italic.eot');
    src: url('br_firma_light_italic.eot?#iefix') format('embedded-opentype'),
         url('br_firma_light_italic.woff2') format('woff2'),
         url('br_firma_light_italic.woff') format('woff'),
         url('br_firma_light_italic.ttf') format('truetype'),
         url('br_firma_light_italic.svg#br_firmalight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'br_firmalight';
    src: url('br_firma_light.eot');
    src: url('br_firma_light.eot?#iefix') format('embedded-opentype'),
         url('br_firma_light.woff2') format('woff2'),
         url('br_firma_light.woff') format('woff'),
         url('br_firma_light.ttf') format('truetype'),
         url('br_firma_light.svg#br_firmalight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'br_firmamedium_italic';
    src: url('br_firma_medium_italic.eot');
    src: url('br_firma_medium_italic.eot?#iefix') format('embedded-opentype'),
         url('br_firma_medium_italic.woff2') format('woff2'),
         url('br_firma_medium_italic.woff') format('woff'),
         url('br_firma_medium_italic.ttf') format('truetype'),
         url('br_firma_medium_italic.svg#br_firmamedium_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'br_firmamedium';
    src: url('br_firma_medium.eot');
    src: url('br_firma_medium.eot?#iefix') format('embedded-opentype'),
         url('br_firma_medium.woff2') format('woff2'),
         url('br_firma_medium.woff') format('woff'),
         url('br_firma_medium.ttf') format('truetype'),
         url('br_firma_medium.svg#br_firmamedium') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'br_firmaregular_italic';
    src: url('br_firma_regular_italic.eot');
    src: url('br_firma_regular_italic.eot?#iefix') format('embedded-opentype'),
         url('br_firma_regular_italic.woff2') format('woff2'),
         url('br_firma_regular_italic.woff') format('woff'),
         url('br_firma_regular_italic.ttf') format('truetype'),
         url('br_firma_regular_italic.svg#br_firmaregular_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'br_firmaregular';
    src: url('br_firma_regular.eot');
    src: url('br_firma_regular.eot?#iefix') format('embedded-opentype'),
         url('br_firma_regular.woff2') format('woff2'),
         url('br_firma_regular.woff') format('woff'),
         url('br_firma_regular.ttf') format('truetype'),
         url('br_firma_regular.svg#br_firmaregular') format('svg');
    font-weight: normal;
    font-style: normal;

}